<template>
  <v-dialog v-model="dialog" width="680" @click:outside="cancel" :persistent="isLoading">
    <v-card>
      <v-card-title>
        <h3><strong>ユーザ{{ statusStr }}化確認</strong></h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <label>ユーザ名【{{ userName }}】を{{ statusStr }}にします。よろしいですか？</label>
            </v-col>
          </v-row>
        </v-container>
        <span class="ml-4" v-if="errorMessage" style="color: red">
          {{ errorMessage }}
        </span>
        <div class="ml-4" v-for="data in errorList" :key="data.message" style="color: red">
          {{ data.message }}
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" :disabled="isLoading"> キャンセル </v-btn>
        <v-btn color="indigo darken-1" dark @click="ok" :loading="isLoading" :disabled="false">
          {{ statusStr }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/apis/staff";
import constant from "@/utils/constant";

export default {
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
    handleOk: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    userName() {
      return this.dialogData.user.name;
    },
    statusStr() {
      return this.dialogData.user.status == constant.USER_STATUS.INVALID ? "有効" : "無効";
    },
  },
  data() {
    return {
      dialog: true,
      isLoading: false,

      errorMessage: "",
      errorList: [],
    };
  },
  methods: {
    cancel() {
      if (this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;
      const status = this.dialogData.user.status == constant.USER_STATUS.INVALID
        ? constant.USER_STATUS.VALID : constant.USER_STATUS.INVALID;
      api.post("/user/status/change", {
        userId: this.dialogData.user.loginId,
        status: status,
      })
        .then((response) => {
          if (response.data?.status == 0) {
            this.errorMessage = "";
            this.errorList = [];
            this.handleOk();
          } else {
            this.errorMessage = response.data?.errorMessage;
            this.errorList = response.data?.errorList;
          }
        })
        .catch(() => {
          this.errorMessage = "不明なエラーが発生しました";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
