<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                theme="dark"
                class="ml-4 px-8 bg-grey-darken-3"
                elevation="2"
                @click="returnbutton"
                >戻る</v-btn
              >
            </v-col>
          </v-row> 
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in afterFilter" :key="header.value" @click.stop="tableSort(header.value)" :style="cursorCheck(header.value)">{{ header.text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <template v-for="header in afterFilter" :key="header.value">
                      <template v-if="header.value == 'comment'">
                        <template v-if="data[header.value]">
                          <v-tooltip location-strategy="connected" location="start top" origin="end top" scroll-strategy="reposition" :width="calcTooltipWidth(data[header.value])">
                            <template v-slot:activator="{ props }">
                              <td v-bind="props" :style="cursorCheck()" >有</td>
                            </template>
                            <p style="word-break: break-all;">
                                {{ data[header.value] }}
                            </p>
                          </v-tooltip>
                        </template>
                        <td v-else></td>
                      </template>

                      <template v-else>
                        <td>{{ convertListData(data, header.value) }}</td>
                      </template>

                    </template>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    label="１ページ当たりの表示件数"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}件目 / {{ dataList.length }}件中
                </v-col>
              </v-row>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click="downloadCsv"
                :disabled="dataList.length == 0"
                >CSV出力</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
//import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import constant from "@/utils/constant";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  //components: {AppWrapperView, DatePicker, ZeroData},
  components: {AppWrapperView, ZeroData},
  props: {},
  computed: {
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      tempArray = tempArray.map((x, index) => {
        x.shukkaDate = utils.putSplitWordToCalendar(x.shukkaDate);
        x.startDate = utils.getCalendarStr(new Date(x.startDate));
        x.endDate = utils.getCalendarStr(new Date(x.endDate));
        x.no = index + 1;
        return x;
      })

      return tempArray;
    },

    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },

    // 画面表示項目のfilter
    afterFilter() {
        //filterをする
        return this.headers.filter((head) => head.enable);
    }

  },

  data: () => ({

    calcTooltipWidth(str) {
      var length = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
          length += 0.8;
        } else {
          length += 1.2;
        }
      }
      return length >= constant.COMMENT_MAX_WIDTH ? constant.COMMENT_MAX_WIDTH+"em" : length+"em";
    },

    currentPageNo: 1,

    //１ページ表示数
    displayPerPage: {name: "15件", value: 15},
    displayPerPageItem: [
      {name: "5件", value: 5},
      {name: "10件", value: 10},
      {name: "15件", value: 15},
      {name: "すべて", value: 0},
    ],

    //ソートの状態
    sortState: {target: "", ascent: true},

    headers:[
      {text: 'No', value: 'no', enable: false},
      {text: '管理ID', value: 'managementId', enable: false},
      {text: '管理ID内行番号', value: 'lineNumber', enable: false},
      {text: '伝票番号', value: 'denpyoNumber', enable: true},
      //{text: '伝票番号区分', value: 'denpyoNumberKubun', enable: false},
      {text: '実績区分', value: 'syoriKubun', enable: false},
      {text: '実績区分', value: 'syoriKubunLabel', enable: false},
      //{text: '担当者ID', value: 'staffId', enable: false},
      {text: '出荷日', value: 'shukkaDate', enable: true},
      {text: '出荷元店舗CD', value: 'shukkamotoCode', enable: true},
      {text: '出荷元店舗', value: 'shukkamotoName', enable: true},
      //{text: '出荷元店舗ゾーンCD', value: 'shukkamotoZoneCode', enable: false},
      //{text: '出荷元店舗ゾーン', value: 'shukkamotoZoneName', enable: false},
      {text: '出荷先店舗CD', value: 'shukkasakiCode', enable: true},
      {text: '出荷先店舗', value: 'shukkasakiName', enable: true},
      //{text: '出荷先店舗ゾーンCD', value: 'shukkasakiZoneCode', enable: false},
      //{text: '出荷先店舗ゾーン', value: 'shukkamotoZoneName', enable: false},
      {text: 'JANコード', value: 'janCode', enable: true},
      {text: '予定数量', value: 'planSuuryo', enable: true},
      {text: '実績数量', value: 'actualSuuryo', enable: true},
      //{text: 'EPCありフラグ', value: 'epcExistsFlag', enable: true},
      //{text: '連携管理開始フラグ', value: 'isRelayStarted', enable: true},
      {text: '商品名', value: 'productName', enable: true},
      {text: '品番CD', value: 'productCode', enable: true},
      {text: 'カラーCD', value: 'colorCode', enable: true},
      {text: 'カラー', value: 'colorName', enable: true},
      {text: 'サイズCD', value: 'sizeCode', enable: true},
      {text: 'サイズ', value: 'sizeName', enable: true},
      //{text: '本体価格', value: 'price', enable: false},
      //{text: '税込価格', value: 'tax', enable: false},
      //{text: '商品サンプル区分', value: 'sampleKbn', enable: false},
      //{text: 'ブランドCD', value: 'brandCode', enable: false},
      //{text: 'ブランド', value: 'brandName', enable: false},
      //{text: 'サブブランドCD', value: 'subBrandCode', enable: false},
      //{text: 'サブブランド', value: 'subBrandName', enable: false},
      //{text: '年度CD', value: 'yearCode', enable: false},
      //{text: '年度', value: 'yearName', enable: false},
      //{text: 'シーズンCD', value: 'seasonCode', enable: false},
      //{text: 'シーズン', value: 'seasonName', enable: false},
      //{text: '大分類', value: 'catergory', enable: false},
      //{text: '中分類', value: 'subcatergory', enable: false},
      //{text: '小分類', value: 'subsubcatergory', enable: false},
      //{text: '原産国', value: 'originCountry', enable: false},
      //{text: 'メーカーコード', value: 'makerCode', enable: false},
      //{text: 'メーカー品番', value: 'makerProductCode', enable: false},
      //{text: 'メーカー名', value: 'makerName', enable: false},
      //{text: '適用開始日', value: 'startDate', enable: false},
      //{text: '適用終了日', value: 'endDate', enable: false},

      // 20230510 add コメントの追加
      {text: 'コメント', value: 'comment', enable: true},
      {text: 'SHIPPING_RESULT_ITEM1', value: 'spareItem1', enable: false},
      {text: 'SHIPPING_RESULT_ITEM2', value: 'spareItem2', enable: false},
      {text: 'SHIPPING_RESULT_ITEM3', value: 'spareItem3', enable: false},
      {text: 'SHIPPING_RESULT_ITEM4', value: 'spareItem4', enable: false},
      {text: 'SHIPPING_RESULT_ITEM5', value: 'spareItem5', enable: false},
      {text: 'SHIPPING_RESULT_ITEM6', value: 'spareItem6', enable: false},
      {text: 'SHIPPING_RESULT_ITEM7', value: 'spareItem7', enable: false},
      {text: 'SHIPPING_RESULT_ITEM8', value: 'spareItem8', enable: false},
      {text: 'SHIPPING_RESULT_ITEM9', value: 'spareItem9', enable: false},
      {text: 'SHIPPING_RESULT_ITEM10', value: 'spareItem10', enable: false},
    ],

    shukkamotoItem: [],
    shukkasakiItem: [],

    dataList: [],

    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,

  }),
  
  methods: {

    // カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    // 出荷実績明細取得
    //${api.basePath}/shop/${store.ownData.getters.currentShopId()}/nyuuka/scm
    search() {
      this.isSearching = true;
      api.post( "/shukka/actual/detail/search", {
          managementId: store.getShukkaManagementId(),
          lineNo: store.getShukkaLineNumber(),
          denpyoNumber: store.getShukkaDenpyoNumber()
        } 
      )
      .then((response)=>{
        this.dataList = response.data.results?.shukkaList ?? [];
        this.resetCondition();

        // 0件ダイアログ処理
        if(this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;

      })
      .catch((error) => {
        // 0件 dialog
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      })
    },

    // 戻るボタン
    returnbutton() {
      // 伝票番号
      router.push({name:Paths.SHUKKA})
    },

    downloadCsv() {
      let dataArr = []
      let arr = []
      let head = this.headers.filter((head) => head.enable)
      head.forEach(function (v) {
        arr.push(v.text)
      })
      dataArr.push(arr.join(",") + "\r\n")

      for (const v of this.sortedTable) {
        let arr = []
        for (const h of head) {
          arr.push(this.convertListData(v, h.value));
        }
        dataArr.push(arr.join(",") + "\r\n")
      }
      let stamp = utils.csvTimeStamp(new Date())
      utils.csvDownload(dataArr.join(""), `出荷明細_${stamp}`)
    },

    convertListData(data, headerVal) {

      const rawVal = data[headerVal];

      // 予備項目系
      if (headerVal.indexOf("spareItem") != -1) {
        var spareItemMap = JSON.parse(store.getShukkaResultSpareItemMap());
        var spareItemInfo = spareItemMap[headerVal.replaceAll("spareItem", "")];

        if (!spareItemInfo || !spareItemInfo.convertList) return rawVal;

        const convInfo = spareItemInfo.convertList.find(e => e.code == data[headerVal]);
        if (!convInfo) return rawVal;

        return convInfo.label;
      }
      return rawVal;
    },

  },
  created() {
    //do nothing
  },
  
  mounted() {
    var spareItemMap = JSON.parse(store.getShukkaResultSpareItemMap());
    if (spareItemMap) {
      for (let i = 1; i<= 10; i++) {
        if (spareItemMap[i]?.label) {
          this.headers.find((head) => head.text == "SHIPPING_RESULT_ITEM" + i).enable = true;
          this.headers.find((head) => head.text == "SHIPPING_RESULT_ITEM" + i).text = spareItemMap[i]?.label;
        }
      }
    }
    this.search()
  }
};
</script>

<style>

</style>
