<template>
  <v-text-field
    class="none-pointer-events"
    density="compact"
    variant="underlined"
    hide-details="auto"
    v-model="dateStr"
    :label="label"
    prepend-icon="mdi-calendar"
    :clearable="true"
    @click:clear="this.$refs.datePickerRef.clearValue()"
    @mousedown="this.$refs.datePickerRef.openMenu()"
    readonly
  ></v-text-field>
  <Datepicker 
    v-model="date" 
    ref="datePickerRef" 
    @update:model-value="save()" 
    locale="ja" 
    select-text="選択" 
    cancel-text="キャンセル"
    :enable-time-picker="false"
    :preview-format="format"
    position="left"
    :auto-position="false"
    week-start="0"
    >
    <template #trigger>
    </template>
  </Datepicker>

</template>

<script>
import utils from '@/utils'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {Datepicker},
  props: {
    modelValue: String,
    label: String,
  },
  data: () => ({
    date: "",
    datePickerRef: "",
  }),
  
  computed: {
    dateStr() {
      if(!(this.date instanceof Date)) return ""
      return utils.getCalendarStr(this.date);
    },
  },
  methods: {
    clear() {
      this.$refs.datePickerRef.clearValue()
    },
    //変更を検知 > inputのemitを更新する値と一緒に流すと親のv-modelのやつが変わる
    save() {
      this.$emit("update:modelValue", this.dateStr);
    },

    format: (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    setDate(d) {
      this.date = d;
      this.save();
    },
  },
};
</script>

<style>
  /* .none-pointer-events{
    pointer-events: none;
  } */
</style>
