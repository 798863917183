<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.loginId"
                label="ログインID" />
            </v-col>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.name"
                label="名前" />
            </v-col>
            <v-col cols="3">
              <v-select density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.role"
                :items="roleItem" item-title="code_name" item-value="code" label="ロール" return-object :clearable="true" />
            </v-col>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto"
                v-model="searchCondition.possibleToLogInStore" label="ログイン可能店舗" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn text @click="clear">クリア</v-btn>
              <v-btn theme="dark" class="ml-4 px-8 bg-grey-darken-3" elevation="2" @click.prevent="search"
                :loading="isSearching">検索</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn dark class="bg-indigo-darken-1" elevation="2" @click="showDialog4Register">新規登録</v-btn>
              <input ref="uploadBtn" type="file" accept=".csv" style="display: none" @change="selectedFile" />
              <v-btn dark class="ml-4 bg-indigo-darken-1" elevation="2" @click="onCsvUploadBtnClick">CSV取込</v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header.value" @click.stop="tableSort(header.value)"
                      :style="cursorCheck(header.value)">{{ header.text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no"
                    v-bind:style="{ backgroundColor: data.status == constant.USER_STATUS.INVALID ? 'lightgray' : '' }">
                    <template v-for="header in headers" :key="header.value">

                      <template v-if="header.value == 'role'">
                        <td>{{ this.getRoleByUser(data).label }}</td>
                      </template>

                      <template v-else-if="header.value == 'update'">
                        <td v-if="data.status == constant.USER_STATUS.VALID">
                          <v-btn size="small" @click="showDialog4Change(data)" color="blue">変更</v-btn>
                        </td>
                        <td v-else></td>
                      </template>

                      <template v-else-if="header.value == 'activate'">
                        <td v-if="data.status == constant.USER_STATUS.VALID">
                          <v-btn size="small" @click="showDialog4Activate(data)">無効</v-btn>
                        </td>
                        <td v-else>
                          <v-btn size="small" @click="showDialog4Activate(data)" color="green">有効</v-btn>
                        </td>
                      </template>

                      <template v-else-if="header.value == 'possibleToLogInStoreList'">
                        <td>
                          <template v-if="data.isSysAdmin">
                            全店舗
                          </template>
                          <template v-else v-for="store in data.possibleToLogInStoreList" :key="store">
                            {{ store.storeName }}<br />
                          </template>
                        </td>
                      </template>

                      <template v-else>
                        <td>{{ data[header.value] }}</td>
                      </template>
                    </template>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination v-model="currentPageNo" :length="maxPageNo" />
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select density="compact" variant="outlined" hide-details="auto" v-model="displayPerPage"
                    :items="displayPerPageItem" item-title="name" item-value="value" label="１ページ当たりの表示件数"
                    @update:modelValue="resetCondition()" return-object />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{ displayContentNo.minNo }} - {{ displayContentNo.maxNo }}件目 / {{ dataList.length }}件中
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-container>
        <UserRegisterDialog v-if="registerDialogVisible" :dialog-data="registerDialog" :handle-ok="confirmRegisterOk"
          :handle-cancel="confirmRegisterCancel" />
        <UserActivateDialog v-if="activateDialogVisible" :dialog-data="activateDialog" :handle-ok="confirmActivateOk"
          :handle-cancel="confirmActivateCancel" />
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog" />
        <MessageDialog v-if="commonMsgDlgVisible" :dialog-data="data4MsgDialog" :handle-ok="closeMessageDataDialog" />
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import api from "@/apis/staff";
import utils from "@/utils";
import constant from "@/utils/constant";
import AppWrapperView from "@/components/views/AppWrapperView";
import MessageDialog from "@/components/dialog/MessageDialog";
import UserRegisterDialog from "@/components/dialog/UserRegisterDialog";
import UserActivateDialog from "@/components/dialog/UserActivateDialog";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: { AppWrapperView, UserRegisterDialog, ZeroData, MessageDialog, UserActivateDialog, },
  props: {},
  computed: {
    constant() {
      return constant;
    },
    // ソート処理
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      // ロールの場合
      if (this.sortState.target == "role") {
        return tempArray.sort(this.roleSorter(this.sortState.ascent));
      }
      // ログイン可能店舗
      if (this.sortState.target == "possibleToLogInStoreList") {
        return tempArray.sort(this.storeListSorter(this.sortState.ascent));
      }
      // その他
      if (this.sortState.target != "") {
        return tempArray.sort(this.defaultSorter(this.sortState.ascent));
      }
      return tempArray;
    },
    // ページング関連処理 START ====================================
    // どの部分を表示しているのかを表示
    displayContentNo() {
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if (maxNo > this.dataList.length || this.displayPerPage.value == 0) {
        return { minNo: minNo, maxNo: this.dataList.length };
      }
      return { minNo: minNo, maxNo: maxNo };
    },
    // 最大ページ
    maxPageNo() {
      if (this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length / this.displayPerPage.value);
    },
    // ページ区切り後のデータ
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo - 1, this.displayContentNo.maxNo);
    },
  },

  data: () => ({
    // 表示ページ
    currentPageNo: 1,
    //１ページ表示数
    displayPerPage: { name: "15件", value: 15 },
    displayPerPageItem: [
      { name: "5件", value: 5 },
      { name: "10件", value: 10 },
      { name: "15件", value: 15 },
      { name: "すべて", value: 0 },
    ],
    // ソートの状態
    sortState: { target: "", ascent: true },
    // ヘッダ
    headers: [
      { text: 'ログインID', value: 'loginId' },
      { text: '名前', value: 'name' },
      { text: 'ロール', value: 'role' },
      { text: 'ログイン可能店舗', value: 'possibleToLogInStoreList' },
      { text: '', value: 'update' },
      { text: '', value: 'activate' },
    ],
    // ロールリスト
    roleItem: [],
    // 店舗リスト
    shopList: [],
    // 検索結果格納
    dataList: [],
    // 検索条件
    searchCondition: {
      loginId: "",                // ログインID
      name: "",                   // 名前
      role: null,                 // ロール
      possibleToLogInStore: "",   // ログイン可能店舗
    },
    // 検索中
    isSearching: false,
    // 登録ダイアログ表示フラグ
    registerDialogVisible: false,
    // ユーザ登録ダイアログに渡すデータ
    registerDialog: {
      user: null,
    },
    // 有効無効ダイアログ表示フラグ
    activateDialogVisible: false,
    // 有効無効ダイアログに渡すデータ
    activateDialog: {
      user: null,
    },
    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 汎用メッセージダイアログ
    commonMsgDlgVisible: false,
    data4MsgDialog: {
      message: "",
      errorList: [],
    },
  }),

  methods: {
    //テーブルの条件リセット
    resetCondition() {
      this.currentPageNo = 1;
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    // ユーザ情報から権限のIDを返却
    getRoleByUser(user) {
      if (user.isSysAdmin) return constant.ROLE.ADMIN;
      if (user.possibleToLogInStoreList.length > 1) return constant.ROLE.ZONE_MANAGER;
      return constant.ROLE.SITE_USER;
    },
    // カーソルの変更
    cursorCheck() {
      return "cursor: pointer;"
    },
    // ロールリスト設定
    getRoleList() {
      this.roleItem = [];
      utils.getRoleList().forEach(element => {
        this.roleItem.push({ name: element.label, code: element.id, code_name: element.label })
      })
    },
    // 検索条件クリア
    clear() {
      this.searchCondition = {
        loginId: "",                // ログインID
        name: "",                   // 名前
        role: null,                 // ロール
        possibleToLogInStore: null, // ログイン可能店舗
      }
    },
    // 検索処理
    search() {
      this.isSearching = true;
      api.post("/user/search", {
        loginId: this.searchCondition.loginId,
        name: this.searchCondition.name,
        role: this.searchCondition.role?.code ?? "",
        possibleToLogInStore: this.searchCondition.possibleToLogInStore,
      })
        .then((response) => {
          this.dataList = response.data.results?.userList ?? [];
          this.resetCondition();
          // 0件ダイアログ処理
          if (this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
          this.isSearching = false;
        })
        .catch(() => {
          // 0件 dialog
          this.dataList = [];
          this.zeroDataWarningDialogFlg = true;
          this.isSearching = false;
        })
    },
    // ソート関連処理 START ================================================
    // ソート
    tableSort(headValue) {
      if (headValue == "no") return;
      if (this.sortState.target == headValue) {
        this.sortState.ascent = !this.sortState.ascent;
      } else {
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },
    // 汎用的なソート関数を返却する
    defaultSorter(isAscent) {
      // a,b はユーザ情報が格納されているとする
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (a[this.sortState.target] > b[this.sortState.target]) return 1 * multiplier;
        if (a[this.sortState.target] < b[this.sortState.target]) return -1 * multiplier;
        return 0;
      }
    },
    // 権限に対するソート関数を返却する
    roleSorter(isAscent) {
      // a,b はユーザ情報が格納されているとする
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (this.getRoleByUser(a).id > this.getRoleByUser(b).id) return 1 * multiplier;
        if (this.getRoleByUser(a).id < this.getRoleByUser(b).id) return -1 * multiplier;
        return 0;
      }
    },
    // ログイン可能店舗に対するソート関数を返却する
    storeListSorter(isAscent) {
      // a,b はユーザ情報が格納されているとする
      return (a, b) => {
        const getRefVal = (user) => {
          if (user.isSysAdmin) return "全店舗";
          if (user.possibleToLogInStoreList.length != 0) {
            return user.possibleToLogInStoreList[0].storeName;
          }
          return "";
        };
        const multiplier = isAscent ? 1 : -1;
        if (getRefVal(a) > getRefVal(b)) return 1 * multiplier;
        if (getRefVal(a) < getRefVal(b)) return -1 * multiplier;
        return 0;
      }
    },
    // 汎用ダイアログ関連処理 START ========================================
    // 0件ダイアログを閉じる
    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },
    // 共通メッセージダイアログを閉じる
    closeMessageDataDialog() {
      this.commonMsgDlgVisible = false;
    },
    // 新規登録ダイアログ関連処理 START ====================================
    // 登録ダイアログ表示
    showDialog4Register() {
      this.showDialogCommonRegister(null, 'new');
    },
    // 更新ダイアログ表示
    showDialog4Change(user) {
      this.showDialogCommonRegister(user, 'upd');
    },
    // 登録/更新ダイアログ表示
    showDialogCommonRegister(user, mode) {
      this.registerDialogVisible = true;
      this.registerDialog.user = user;
      this.registerDialog.mode = mode;
      this.registerDialog.shopList = this.shopList;
    },
    // 登録/更新ダイアログ非表示
    closeRegisterDialog() {
      this.registerDialogVisible = false;
      this.registerDialog.user = null;
    },
    // 登録/更新ダイアログキャンセル
    confirmRegisterCancel() {
      this.closeRegisterDialog();
    },
    // 登録/更新ダイアログOK押下
    confirmRegisterOk() {
      this.closeRegisterDialog();
      this.search();
    },
    // 有効無効切り替えダイアログ関連処理 START ============================
    // ダイアログ表示
    showDialog4Activate(data) {
      this.activateDialogVisible = true;
      this.activateDialog.user = data;
    },
    // ダイアログ非表示
    closeActivateDialog() {
      this.activateDialogVisible = false;
      this.activateDialog.user = null;
    },
    // ダイアログキャンセル
    confirmActivateCancel() {
      this.closeActivateDialog();
    },
    confirmActivateOk() {
      this.closeActivateDialog();
      this.search();
    },
    // CSV取込関連処理 START ===============================================
    // CSV取込ボタン押下
    onCsvUploadBtnClick() {
      this.$refs.uploadBtn.click();
    },
    // CSV取込本処理
    async selectedFile() {
      let formData = new FormData();
      formData.append("file", this.$refs.uploadBtn.files[0]);
      api.postFile("/user/loadcsv", formData)
        .then((response) => {
          this.data4MsgDialog = {};
          this.data4MsgDialog.errorList = response.data.errorList;
          this.data4MsgDialog.message =
            (response.data.status == constant.RESPONSE_STATUS.SUCCESS) ? "CSV取込に成功しました" : response.data.errorMessage
          this.commonMsgDlgVisible = true;
          this.search();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$refs.uploadBtn.value = null;
        });
    },
  },
  created() {
    this.getRoleList()
  },
  mounted() {
    // 店舗リスト取得
    api.post("/store/search/all", {})
      .then((response) => {
        if (response.status == constant.RESPONSE_STATUS.FAILURE) return; //do nothing
        this.shopList = [];
        response.data.results?.shopList.forEach(element => {
          this.shopList.push({ name: element.name, code: element.code, code_name: element.code + '_' + element.name })
        })
      })
      .catch(() => {
        //do nothing
      })
  },
};
</script>
