<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="3" class="print_col">
              <v-select
                density="compact"
                variant="outlined"
                hide-details="auto"
                :items="zoneList"
                label="ロケ名"
                item-title="zoneName"
                item-value="zoneCode"
                v-model="searchCondition.targetZoneCode"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="3" class="print_col">
              <v-select
                  density="compact"
                  variant="outlined"
                  hide-details="auto"
                  :items="[
                      {'key':1, 'label':'過少'},
                      {'key':2, 'label':'過剰'},
                      {'key':3, 'label':'一致'},
                      ]"
                  label="差異"
                  item-title="label"
                  item-value="key"
                  v-model="searchCondition.filterLoss"
                  clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header.value" @click.stop="tableSort(header.value)" :style="cursorCheck(header.value)">{{ header.text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <td v-for="header in headers" :key="header.value">{{ data[header.value] }}</td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    label="１ページ当たりの表示件数"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}件目 / {{ sortedTable.length }}件中
                </v-col>
              </v-row>

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                class="px-8 bg-indigo-darken-1 mr-5"
                elevation="2"
                @click="downloadCsv"
                :disabled="dataList.length == 0">差異表CSV出力
              </v-btn>

              <v-btn
                dark
                class="px-8 bg-indigo-darken-1"
                elevation="2"
                @click="print"
                :disabled="dataList.length == 0">差異表印刷
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <ErrorDialog v-if="dataError" :URL="previousURL"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import api from "@/apis/staff";
import store from "@/store";
import utils from "@/utils";
import ErrorDialog from "@/components/dialog/ErrorDialog";

// 棚卸区分
const namezero = "-";
const	namerelay = "RFID在庫";
const	nameerp = "APV在庫";

export default {
  components: {AppWrapperView, ErrorDialog},
  computed: {
    // １つ目は必ず不必要なゾーンなので、除外する
    zoneList() {
      const clonedZoneList = this.tanaoroshi?.zoneList.map(x => x) ?? [];
      clonedZoneList.shift();
      // APV在庫の場合はゾーンコードリストを表示しない
      if(this.tanaoroshi?.inventoryModeLabel == nameerp){
        return [];
      }
      return clonedZoneList;
    },
    
    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.sortedTable.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.sortedTable.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.sortedTable.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }

      // フィルター
      // ロケ
      if(this.searchCondition.targetZoneCode != null) {
        tempArray = tempArray.filter(x => x.zoneCode == this.searchCondition.targetZoneCode)
      }
      
      // 差異
      if(this.searchCondition.filterLoss != null) {
        // 過少 予定数 > 読み取り数
        if(this.searchCondition.filterLoss == 1) tempArray = tempArray.filter(x => x.planSuuryo > x.resultSuuryo)
        // 過剰 予定数 < 読み取り数
        if(this.searchCondition.filterLoss == 2) tempArray = tempArray.filter(x => x.planSuuryo < x.resultSuuryo)
        // 一致 予定数 = 読み取り数
        if(this.searchCondition.filterLoss == 3) tempArray = tempArray.filter(x => x.planSuuryo == x.resultSuuryo)
      }

      // 変換
      tempArray = tempArray.map((x, index) => {
        // noはなし
        x.no = index + 1;
        return x;
      })
      
      return tempArray;
    },

    // ページ区切り後のデータ
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },

  },
  data: () => ({
    // paramより
    tanaoroshi: null,
    shopCode: null,
    dataError: false,
    dataList: [],

    searchCondition: {
      targetZoneCode: null, // ロケーション
      filterLoss : null, // 差異
    },

    // 表
    headers:[
      {text: 'No', value: 'no',},
      {text: 'ブランド', value: 'brandName',},
      {text: '品番', value: 'productCode',},
      {text: '品名', value: 'productName',},
      {text: '年度', value: 'yearName',},
      {text: 'シーズン', value: 'seasonName',},
      {text: 'JANコード', value: 'janCode',},
      {text: 'カラー名', value: 'colorName',},
      {text: 'サイズ', value: 'sizeName',},
      {text: '予定数', value: 'planSuuryo',},
      {text: '実績数', value: 'resultSuuryo',},
      {text: '差異数', value: 'diffSuuryo',},
      {text: 'ロケ名', value: 'zoneName',},
      // {text: 'ゾーンコード', value: 'zoneCode',},
      // {text: 'カラーCD', value: 'colorCode',},
      // {text: 'サイズCD', value: 'sizeCode',},
      // {text: '本体価格', value: 'price',},
      // {text: '税込価格', value: 'tax',},
      // {text: '商品サンプル区分', value: 'sampleKbn',},
      // {text: 'ブランドCD', value: 'brandCode',},
      // {text: 'サブブランドCD', value: 'subBrandCode',},
      // {text: 'サブブランド', value: 'subBrandName',},
      // {text: '年度CD', value: 'yearCode',},
      // {text: 'シーズンCD', value: 'seasonCode',},
      // {text: '大分類', value: 'category',},
      // {text: '中分類', value: 'subcategory',},
      // {text: '小分類', value: 'subsubcategory',},
      // {text: '原産国', value: 'originCountry',},
      // {text: 'メーカーコード', value: 'makerCode',},
      // {text: 'メーカー名', value: 'makerName',},
      // {text: 'メーカー品番', value: 'makerProductCode',},
      // {text: '適用開始日', value: 'startDate',},
      // {text: '適用終了日', value: 'endDate',},
    ],
    currentPageNo: 1,
    //１ページ表示数
    displayPerPage: {name: "15件", value: 15},
    displayPerPageItem: [
      {name: "5件", value: 5},
      {name: "10件", value: 10},
      {name: "15件", value: 15},
      {name: "すべて", value: 0},
    ],
    //ソートの状態
    sortState: {target: "", ascent: true},

    // エラー時の遷移先
    previousURL: Paths.TANAOROSHI,

  }),

  methods: {

    cursorCheck(value){
      return "cursor: pointer;"
    },

    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    reload() {
      api.post("/tanaoroshi/diff/list/search", {
          tanaoroshiId: this.tanaoroshi.tanaoroshiId ?? "",
          shopCode: this.shopCode ?? "",
        }
      )
      .then((response)=>{
        if(response.data.status == '1' || !(response.data.results?.diffList?.length ?? 0 > 0)){
          this.dataError = true;
        }
        this.dataList = response.data.results?.diffList ?? [];
      })
      .catch((error) => {
        // back previous page
        this.dataError = true;
      })
      this.resetCondition();
    },
    downloadCsv() {
      var dataArr = []
      var arr = []
      let head = this.headers
      head.forEach(function (v) {
        arr.push(v.text)
      })
      dataArr.push(arr.join(",") + "\r\n")

      var rowNum = 0
      this.sortedTable.forEach((v, idx) => {
        if(v.diffSuuryo === 0){
          return
        }
        var arr = []
        head.forEach(function (h, i) {
          arr.push(v[h.value])
        })
        dataArr.push(arr.join(",") + "\r\n")
      })

      let shopCode = this.shopCode
      let stamp = utils.csvTimeStamp(new Date())

      utils.csvDownload(dataArr.join(""), `棚卸差異表_${shopCode}_${stamp}`)
    },
    print() {
      window.print();
    }

  },
  mounted() {
    //propで渡された選択中zoneCodeをformにbindしているものに移す
    if (!store.hasTanaoroshiParam()) {
      this.dataError = true;
    }
    else {
      this.tanaoroshi = store.getTanaoroshi();
      this.shopCode = store.getTanaShopCode();
      this.searchCondition.targetZoneCode = store.getTanaZoneCode();
      if(this.tanaoroshi?.zoneList.at(0).zoneCode == this.searchCondition.targetZoneCode) this.searchCondition.targetZoneCode = null;
      this.reload()
    }
    
  }
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}

.print_content {
  display: none;
  font-size: 12px;
  font-weight: bold;
  color: #555;
  text-align: left;
}

.print_head {
  padding-left: 2px;
}

.print_data_row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.print_head,
.print_data {
  width: 9%;
  padding: 4px 2px;
  border-bottom: 1px solid #ccc;
  word-break: break-all;
}

@media print {
  .v-main {
    padding: 0 0 0 0 !important;
  }

  .v-btn {
    display: none !important;
  }

  .v-data-table {
    display: none !important;
  }

  .print_col {
    margin: 0;
    padding: 0;
  }

  .print_content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
</style>
