<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="12">
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                EPC：{{ epcInfo.epc }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                JANコード：{{ epcInfo.janCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                品名：{{ epcInfo.productName }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                品番：{{ epcInfo.productCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                カラーCD：{{ epcInfo.colorCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                カラー名称：{{ epcInfo.colorName }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                サイズCD：{{ epcInfo.sizeCode }}
              </v-chip>
              <v-chip dark class="mr-4 mb-4 bg-indigo-darken-1">
                サイズ名称：{{ epcInfo.sizeName }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
                            <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header.value" @click.stop="tableSort(header.value)" :style="cursorCheck(header.value)">{{ header.text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <td v-for="header in headers" :key="header.value">{{ data[header.value] }}</td>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                      
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                v-model="currentPageNo"
                :length="maxPageNo"/>
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select
                    density="compact"
                    variant="outlined"
                    hide-details="auto"
                    v-model="displayPerPage"
                    :items="displayPerPageItem"
                    item-title="name"
                    item-value="value"
                    label="１ページ当たりの表示件数"
                    @update:modelValue = "resetCondition()"
                    return-object/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  {{displayContentNo.minNo}} - {{displayContentNo.maxNo}}件目 / {{ dataList.length }}件中
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-container>
        <ErrorDialog v-if="dataError" :URL="previousURL"/>
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import utils from "@/utils";
import router from "@/router/index";
import ErrorDialog from "@/components/dialog/ErrorDialog";
import Paths from "@/router/indexPaths";

export default {
  components: {AppWrapperView, ErrorDialog},
  computed: {
    statusOptions() {
      return [{id: '0', label: ''}, {id: '1', label: '済'}]
    },

    headers() {
      return [
        // {text: 'No', sortable: false, value: 'no',},
        {text: 'イベント名', value: 'eventName'},
        {text: 'イベント時刻', value: 'eventTime'},
        {text: '店舗名', value: 'shopName'},
        {text: 'ロケ', value: 'zoneName'},

      ]
    },

    //どの部分を表示しているのか
    displayContentNo(){
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if(maxNo > this.dataList.length || this.displayPerPage.value == 0){
        return {minNo: minNo, maxNo: this.dataList.length};
      }
      return {minNo: minNo, maxNo: maxNo};
    },

    //最大ページ
    maxPageNo() {
      if(this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length/this.displayPerPage.value);
    },

    //ソート&日付変換後のデータ
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if(this.sortState.target != ""){
        //昇順
        if(this.sortState.ascent) tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return 1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return -1;
          }
          return 0;
        })
        //降順
        else tempArray = tempArray.sort((a, b) => {
          if(a[this.sortState.target] > b[this.sortState.target]){
            return -1;
          }
          if(a[this.sortState.target] < b[this.sortState.target]){
            return 1;
          }
          return 0;
        })
      }
      tempArray = tempArray.map((x, index) => {
        x.eventTime = utils.putSplitWordToCalendarWithTime(x.eventTime);
        return x;
      })
      return tempArray;
    },

    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo-1, this.displayContentNo.maxNo);
    },

  },
  data: () => ({
    currentPageNo: 1,

    //１ページ表示数
    displayPerPage: {name: "15件", value: 15},
    displayPerPageItem: [
      {name: "5件", value: 5},
      {name: "10件", value: 10},
      {name: "15件", value: 15},
      {name: "すべて", value: 0},
    ],

    dataList: [],

    epcInfo: {
      shopCode: "",
      epc: "",
      janCode: "",
      productName :"",
      productCode :"",
      colorCode :"",
      colorName :"",
      sizeCode :"",
      sizeName :"",
    },

    //ソートの状態
    sortState: {target: "", ascent: true},
    
    pagination: {
      page: 1,
      itemsPerPage: 15
    },

    // エラー時の遷移先
    previousURL: Paths.ZAIKO_EPC_LIST,

    dataError: false,
  }),
  methods: {
    //カーソルの変更
    cursorCheck(value){
      return "cursor: pointer;"
    },
    //テーブルの条件リセット
    resetCondition(){
      //１ページ目
      this.currentPageNo = 1;
      //ソートなし
      this.sortState.target = "";
      this.sortState.ascent = true;
    },

    tableSort(headValue) {
      if(headValue == "no") return;
      this.currentPageNo = 1;
      if(this.sortState.target == headValue){
        this.sortState.ascent = !this.sortState.ascent;
      }
      else{
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },
    
    search() {
      
      api.post( "/zaiko/epc/event/search", {
          shopCode:  this.epcInfo.shopCode,
          epc:  this.epcInfo.epc
        } 
      )
      .then((response) => {
        if(!(response.data.results?.eventList?.length ?? 0 > 0)) this.dataError = true;

        this.epcInfo.janCode = response.data.results?.janCode
        this.epcInfo.productName = response.data.results?.productName
        this.epcInfo.productCode = response.data.results?.productCode
        this.epcInfo.colorCode = response.data.results?.colorCode
        this.epcInfo.colorName = response.data.results?.colorName
        this.epcInfo.sizeCode = response.data.results?.sizeCode
        this.epcInfo.sizeName = response.data.results?.sizeName
        this.dataList.splice(0, this.dataList.length, ...response.data.results?.eventList);
      })
      .catch((error) => {
        // back previous page
        this.dataError = true;
      });

    },

  },
  created() {
    //do nothing.
  },
  mounted() {
    if (!store.hasZaikoEpcEventParam()) {
      this.dataError = true;
    }
    else {
      this.epcInfo.epc = store.getZaikoEpc();
      this.epcInfo.shopCode = store.getZaikoShopCode();
    }
    this.search()
  }
};
</script>

<style></style>
