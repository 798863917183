<template>
  <v-dialog v-model="dialog" width="680" @click:outside="cancel" :persistent="isLoading">
    <v-card class="pa-4">
      <v-card-title>
        <h3><strong>ユーザ{{ this.dialogData.mode == 'upd' ? "情報変更" : "新規登録" }}</strong></h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="isFormValid" ref="form">
            <v-row>
              <v-col cols="12">
                <label>
                  ログインID*
                  <v-tooltip right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" size="small">mdi-information</v-icon>
                    </template>
                    <span><small>ログインIDは19文字以下で入力してください</small></span>
                  </v-tooltip>
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="loginId" maxlength="19"
                  :disabled="this.dialogData.mode == 'upd'" :rules="[rules.required, rules.maxLength(19)]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>
                  名前*
                  <v-tooltip right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" size="small">mdi-information</v-icon>
                    </template>
                    <span><small>名前は50文字以下で入力してください</small></span>
                  </v-tooltip>
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="name" maxlength="50"
                  :rules="[rules.required, rules.maxLength(50)]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select density="compact" hide-details="auto" v-model="role" :items="roleOptions" label="ロール*"
                  :rules="[rules.required]" :multiple="false" item-value="id" item-title="label" :error-messages="errors"
                  return-object></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="mb-0">
                  パスワード*
                  <v-tooltip right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" size="small">mdi-information</v-icon>
                    </template>
                    <span><small>パスワードは半角英数８文字以上、英数字混在で設定してください</small></span>
                  </v-tooltip>
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="password" type="password"
                  :rules="[rules.password]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="mb-0">
                  パスワード確認*
                  <v-tooltip right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" size="small">mdi-information</v-icon>
                    </template>
                    <span><small>パスワードは半角英数８文字以上、英数字混在で設定してください</small></span>
                  </v-tooltip>
                </label>
                <v-text-field density="compact" hide-details="auto" class="pt-0 mt-0" v-model="passwordConfirm"
                  ref="passwordConfirm" type="password" :rules="[rules.samePass]"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="role && role.id != constant.ROLE.ADMIN.id">
              <v-col cols="12">
                <v-select v-if="role.id == constant.ROLE.ZONE_MANAGER.id" v-model="shopIds" :items="shopOptions"
                  density="compact" hide-details="auto" label="ログイン可能店舗*" :multiple="true" item-value="code"
                  item-title="name" :error-messages="errors" return-object :rules="[rules.shopIds]"></v-select>
                <v-select v-else v-model="shopId" :items="shopOptions" label="ログイン可能店舗*" :rules="[rules.shopId]"
                  density="compact" hide-details="auto" :multiple="false" item-value="code" item-title="name"
                  :error-messages="errors" return-object></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <span class="ml-4" v-if="errorMessage" style="color: red"> {{ errorMessage }}</span>
        <div class="ml-4" v-for="data in errorList" :key="data.message" style="color: red">{{ data.message }}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" :disabled="isLoading"> キャンセル </v-btn>
        <v-btn color="indigo darken-1" dark @click="ok" :loading="isLoading" :disabled="!isFormValid">
          {{ this.dialogData.mode == 'upd' ? "変更" : "登録" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/apis/staff";
import utils from "@/utils";
import constant from "@/utils/constant"

export default {
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
    handleOk: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    // ロールプルダウンの内容
    roleOptions() {
      return utils.getRoleList();
    },
    shopOptions() {
      return this.dialogData.shopList;
    },
    constant() {
      return constant;
    },
  },
  data() {
    return {
      rules: {
        required: v => {
          return this.hasLength(v) || "入力してください";
        },
        maxLength: len => {
          return v => {
            if (!this.hasLength(v)) return true;
            if (v.length <= len) return true;
            return len + "文字以下で入力してください"
          }
        },
        minLength: len => {
          return v => {
            if (!this.hasLength(v)) return true;
            if (v.length >= len) return true;
            return len + "文字以上で入力してください"
          }
        },
        password: v => {
          this.$refs.passwordConfirm.validate();
          if (this.dialogData.mode == 'upd' && !this.hasLength(v)) return true;
          if (this.dialogData.mode == 'new' && !this.hasLength(v)) return "入力してください";
          return RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{8,}$/).test(v) || "半角英数８文字以上、英数字混在で設定してください";
        },
        samePass: v => {
          if (this.dialogData.mode == 'new' && !this.hasLength(v)) return "入力してください";
          return v == this.password || "パスワードとパスワード確認が一致していません"
        },
        digit: v => {
          return RegExp(/^[0-9]*$/).test(v) || "数値以外が含まれます"
        },
        shopIds: v => {
          if (this.role.id == constant.ROLE.ZONE_MANAGER.id && v.length < 2) {
            return "ロールがゾーンマネージャの場合、ログイン可能店舗は2店舗以上指定してください"
          }
          return true;
        },
        shopId: v => {
          if (this.role.id == constant.ROLE.SITE_USER.id && !v) {
            return "ロールが店舗ユーザの場合、ログイン可能店舗は2店舗以上指定できません"
          }
          return true;
        },
      },
      dialog: true,
      isLoading: false,
      isFormValid: true,

      // 入力値
      loginId: "",
      name: "",
      role: "",
      password: "",
      passwordConfirm: "",
      shopId: "",
      shopIds: [],
      // エラー出力
      errorMessage: "",
      errorList: [],
    };
  },
  mounted() {
    if (!this.dialogData.user) return;
    this.loginId = this.dialogData.user.loginId;
    this.name = this.dialogData.user.name;
    this.role = utils.getRoleList().find(e => e.id == this.getRoleId(this.dialogData.user));
    this.password = "";
    this.passwordConfirm = "";
    const roleId = this.getRoleId(this.dialogData.user);

    if (this.dialogData.user.possibleToLogInStoreList.length != 0) {
      if (roleId == constant.ROLE.SITE_USER.id) {
        this.shopId = this.findShop(this.dialogData.user.possibleToLogInStoreList[0]);
      } else if (roleId == constant.ROLE.ZONE_MANAGER.id) {
        const shopList = [];
        for (let id of this.dialogData.user.possibleToLogInStoreList) {
          const shop = this.findShop(id);
          if (shop) {
            shopList.push(shop);
          }
        }
        this.shopIds = shopList;
      }
    }
    this.$refs.form.validate()
  },
  methods: {
    hasLength(v) {
      return !(v == null || v == undefined || v == "");
    },
    findShop(shop) {
      return this.dialogData.shopList.find(e => e.code == shop.siteCode);
    },
    getRoleId(user) {
      if (user.isSysAdmin) return constant.ROLE.ADMIN.id;
      if (user.possibleToLogInStoreList.length > 1) return constant.ROLE.ZONE_MANAGER.id;
      return constant.ROLE.SITE_USER.id;
    },
    cancel() {
      if (this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;
      const param = {
        userId: this.loginId,
        userName: this.name,
        role: this.role.id,
        password: this.password,
      };
      const shopList = [];
      if (this.role.id == constant.ROLE.ZONE_MANAGER.id) {
        for (var shop of this.shopIds) {
          shopList.push(shop.code);
        }
      } else if (this.role.id == constant.ROLE.SITE_USER.id) {
        shopList.push(this.shopId.code);
      }

      param.siteCodeList = shopList;
      param.mode = this.dialogData.mode;
      api.post("/user/register", param)
        .then((response) => {
          if (response.data?.status == 0) {
            this.errorMessage = "";
            this.errorList = [];
            this.handleOk();
          } else {
            this.errorMessage = response.data?.errorMessage;
            this.errorList = response.data?.errorList;
          }
        })
        .catch(() => {
          this.errorMessage = "不明なエラーが発生しました";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.pre-line {
  white-space: pre-line;
}
</style>
