import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from "@/plugins/vuetify";



const app = createApp(App);
//app.config.compilerOptions.isCustomElement = tag => tag.startsWith('Datepicker')
app.use(router).use(vuetify).mount('#app');
