import { AxiosResponse } from "axios";
import constant from "@/utils/constant"

const utils = {

  // epcFlagの〇✕置き換え
  replaceEpcFlagWord(str: string): string{
    if(str == "True" || str == "true") return "〇";
    else if(str == "False" || str == "false") return "✕";
    else return str;
  },

  StringToBool(str: string): boolean{
    if(typeof str == 'boolean') return str;
    if(str == "True" || str == "true") return true;
    return false;
  },

  putSplitWordToCalendarWithTime(str: string): string {
    // hhmmssあり
    if(str?.length >= 14 && !str.includes('-')){
      return str.substring(0, 4) + '-' + str.substring(4, 6) + '-' + str.substring(6, 8) + ' ' + str.substring(8, 10) + ':' + str.substring(10, 12) + ':' + str.substring(12, 14);
    }
    return str;
  },

  putSplitWordToCalendar(str: string): string {
    // YYYYMMDDまで
    if(str?.length >= 8 && !str.includes('-')){
       return str.substring(0, 4) + '-' + str.substring(4, 6) + '-' + str.substring(6, 8);
    }
    return str;
  },
  getCalendarStr(date: Date): string {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  },
  csvDownload(data: string, filename: string) {
    // UTF BOM
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    // リンククリエイト
    const downloadLink = document.createElement("a");
    downloadLink.download = filename + ".csv";
    // ファイル情報設定
    downloadLink.href = URL.createObjectURL(
      new Blob([bom, data], { type: "text/csv" })
    );
    downloadLink.dataset.downloadurl = [
      "text/csv",
      downloadLink.download,
      downloadLink.href,
    ].join(":");
    // イベント実行
    downloadLink.click();
  },
  csvTimeStamp(date: Date) {
    return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}${String(date.getDate()).padStart(2, "0")}${String(
      date.getHours()
    ).padStart(2, "0")}${String(date.getMinutes()).padStart(2, "0")}${String(
      date.getSeconds()
    ).padStart(2, "0")}${String(date.getMilliseconds()).padStart(3, "0")}`;
  },

  //レスポンスからエラーを判断して返す
  //TODO 見せたくないエラーと見せたいエラーで仕分ける
  getErrorMsg(res:AxiosResponse):string {
    //APIにつながる
    if(res.status == 200) {
      //正常時
      if(res.data.status == "0"){
        return "";
      }
      //異常時 
      //return res.data.errorMessage ?? "エラーが発生しました。しばらく時間をおいて、お試しください";
    }
    
    //APIにも繋がらない異常時
    return res.data.errorMessage ?? "エラーが発生しました。しばらく時間をおいて、お試しください";
  },
  getRoleList() {
    return [
      constant.ROLE.ADMIN,
      constant.ROLE.ZONE_MANAGER,
      constant.ROLE.SITE_USER
    ]
  }
};
export default utils;
