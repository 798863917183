<template>
  <v-app>
    <AppWrapperView>
      <v-main>
        <v-container class="pa-8" fluid>
          <v-row>
            <v-col cols="3">
              <v-select density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.siteCode"
                :items="siteCodeList" item-title="code_name" item-value="code" label="店舗" :clearable="false"
                return-object />
            </v-col>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.janCode"
                label="JANコード" />
            </v-col>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto"
                v-model="searchCondition.productNumber" label="品番" />
            </v-col>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="searchCondition.epc"
                label="EPC" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <DatePicker ref="movingDateFrom" v-model="searchCondition.movingDateFrom" label="移動日from"></DatePicker>
            </v-col>
            <v-col cols="3">
              <DatePicker ref="movingDateTo" v-model="searchCondition.movingDateTo" label="移動日to"></DatePicker>
            </v-col>
            <v-col cols="3">
              <v-text-field density="compact" variant="outlined" hide-details="auto"
                v-model="searchCondition.managementId" label="管理ID" />
            </v-col>
            <v-col cols="3" class="text-right">
              <v-btn text @click="clear">クリア</v-btn>
              <v-btn theme="dark" class="ml-4 px-8 bg-grey-darken-3" elevation="2" @click="search"
                :loading="isSearching">検索</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-table density="compact" class="text-no-wrap">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header.value" @click.stop="tableSort(header.value)"
                      :style="cursorCheck(header.value)">{{ header.text }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in filteredDataList" :key="data.no">
                    <template v-for="header in headers" :key="header.value">
                      <td>{{ this.convertCode2Label(header.value, data[header.value]) }}</td>
                    </template>
                  </tr>
                  <tr>
                    <td :colspan="headers.length">
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination v-model="currentPageNo" :length="maxPageNo" />
            </v-col>
            <v-col cols="3">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10" class="d-flex justify-end">
                  <v-select density="compact" variant="outlined" hide-details="auto" v-model="displayPerPage"
                    :items="displayPerPageItem" item-title="name" item-value="value" label="１ページ当たりの表示件数"
                    @update:modelValue="resetCondition()" return-object />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-right">
                  {{ displayContentNo.minNo }} - {{ displayContentNo.maxNo }}件目 / {{ dataList.length }}件中
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn dark class="px-8 bg-indigo-darken-1" elevation="2" @click.prevent="downloadCsv"
                :disabled="dataList.length == 0">CSV出力</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog" />
      </v-main>
    </AppWrapperView>
  </v-app>
</template>

<script lang="js">
import Vue from "vue";
import AppWrapperView from "@/components/views/AppWrapperView";
import DatePicker from "@/components/DatePicker";
import api from "@/apis/staff";
import store from "@/store";
import router from "@/router/index";
import Paths from "@/router/indexPaths";
import utils from "@/utils";
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: { AppWrapperView, DatePicker, ZeroData },
  props: {},


  computed: {

    // ページング情報の返却
    displayContentNo() {
      const minNo = this.dataList.length != 0 ? 1 + (this.displayPerPage.value * (this.currentPageNo - 1)) : 0;
      const maxNo = this.displayPerPage.value * (this.currentPageNo);
      if (maxNo > this.dataList.length || this.displayPerPage.value == 0) {
        return { minNo: minNo, maxNo: this.dataList.length };
      }
      return { minNo: minNo, maxNo: maxNo };
    },

    // 最大ページ
    maxPageNo() {
      if (this.displayPerPage.value == 0) return 0;
      return Math.ceil(this.dataList.length / this.displayPerPage.value);
    },

    // ソート
    sortedTable() {
      let tempArray = this.dataList.map(x => x);
      if (this.sortState.target != "") {
        tempArray = tempArray.sort(this.defaultSorter(this.sortState.ascent));
      }
      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice(this.displayContentNo.minNo - 1, this.displayContentNo.maxNo);
    },
  },


  data: () => ({
    currentPageNo: 1,
    prevRoutePath: "",
    // 1ページ表示数
    displayPerPage: { name: "15件", value: 15 },
    displayPerPageItem: [
      { name: "5件", value: 5 },
      { name: "10件", value: 10 },
      { name: "15件", value: 15 },
      { name: "すべて", value: 0 },
    ],
    // ソートの状態
    sortState: { target: "", ascent: true },
    // ヘッダ
    headers: [
      { text: '管理ID', value: 'managementId' },
      { text: '担当者ID', value: 'staffId' },
      { text: '移動日時', value: 'movingDateTime' },
      { text: '店舗CD', value: 'siteCode' },
      { text: '店舗名', value: 'siteName' },
      { text: 'JANコード', value: 'janCode' },
      { text: '取引区分', value: 'transactionDivisionText' },
      { text: '実績数量', value: 'actualQuantity' },
      { text: 'EPC', value: 'epc' },
      { text: '品番', value: 'productNumber' },
      { text: '品名', value: 'productName' },
      { text: 'カラー', value: 'color' },
      { text: 'サイズ', value: 'size' },
      { text: 'ブランド', value: 'brand' },
    ],
    siteCodeList: [],
    dataList: [],

    // 検索条件
    searchCondition: {
      siteCode: "",
      janCode: "",
      productNumber: "",
      movingDateFrom: "",
      movingDateTo: "",
      managementId: "",
      epc: ""
    },
    // 0件取得時の警告ダイアログ
    zeroDataWarningDialogFlg: false,
    // 検索中
    isSearching: false,
  }),


  methods: {
    // 汎用的なソート関数を返却する
    defaultSorter(isAscent) {
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (a[this.sortState.target] > b[this.sortState.target]) return 1 * multiplier;
        if (a[this.sortState.target] < b[this.sortState.target]) return -1 * multiplier;
        return 0;
      }
    },
    // カーソル表示形式を変更
    cursorCheck(value) {
      return "cursor: pointer;"
    },

    // 店舗検索リスト取得
    getValidShopSearchList() {
      // selectSiteFlag：0：他店舗含む / 1：自店舗のみ
      api.post("/store/search", { selectSiteFlag: 0 })
        .then((response) => {
          if (response.status == 1) return;
          response.data.results?.shopList.forEach(element => {
            this.siteCodeList.push({
              name: element.name,
              code: element.code,
              zoneList: element.zoneList,
              code_name: element.code + '_' + element.name
            })
          })
          if (this.prevRoutePath == Paths.ZAIKO_MOVE_DETAIL) {
            this.searchCondition.siteCode = this.siteCodeList.find(e => e.code == this.searchCondition.siteCode.code);
            return;
          }
          this.searchCondition.siteCode = this.siteCodeList[0];
        })
        .catch((error) => {
          //do nothing
        })
    },

    // テーブルの条件リセット
    resetCondition() {
      this.currentPageNo = 1;
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    tableSort(headValue) {
      if (headValue == "no") return;
      this.currentPageNo = 1;
      if (this.sortState.target == headValue) {
        this.sortState.ascent = !this.sortState.ascent;
      } else {
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },

    clear() {
      this.$refs.movingDateFrom.clear()
      this.$refs.movingDateTo.clear()
      this.searchCondition = {
        siteCode: this.siteCodeList[0],
        janCode: "",
        productNumber: "",
        movingDateFrom: "",
        movingDateTo: "",
        managementId: "",
        epc: ""
      }
    },

    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },

    search() {
      this.isSearching = true;
      api.post("/zaiko/sale-actual/search", {
        siteCode: this.searchCondition.siteCode?.code ?? "",
        janCode: this.searchCondition.janCode,
        productNumber: this.searchCondition.productNumber,
        movingDateFrom: this.searchCondition.movingDateFrom?.replaceAll("-", "") ?? "",
        movingDateTo: this.searchCondition.movingDateTo?.replaceAll("-", "") ?? "",
        epc: this.searchCondition.epc,
        managementId: this.searchCondition.managementId,
      }).then((response) => {
        this.dataList = response.data.results?.zaikoSaleActualResultList ?? [];
        this.resetCondition();
        // 0件ダイアログ処理
        if (this.dataList.length == 0) this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      }).catch((error) => {
        this.dataList = [];
        this.zeroDataWarningDialogFlg = true;
        this.isSearching = false;
      });
    },
    // CSVダウンロード処理
    downloadCsv() {
      let dataArr = []
      let arr = []
      let head = this.headers
      head.forEach(function (v) {
        arr.push(v.text)
      })
      dataArr.push(arr.join(",") + "\r\n")
      for (const v of this.sortedTable) {
        let arr = [];
        for (const h of head) {
          arr.push(this.convertCode2Label(h.value, v[h.value]));
        }
        dataArr.push(arr.join(",") + "\r\n")
      }
      let stamp = utils.csvTimeStamp(new Date())
      utils.csvDownload(dataArr.join(""), `販売実績_${stamp}`)
    },
    convertCode2Label(headerValue, val) {
      return val;
    }
  },
  created() {
    this.getValidShopSearchList()
  },
};
</script>

<style></style>
