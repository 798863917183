<template>
  <v-text-field class="none-pointer-events" density="compact" variant="underlined" hide-details="auto"
    v-model="yearMonthStr" :label="label" prepend-icon="mdi-calendar" :clearable="true"
    @click:clear="this.$refs.datePickerRef.clearValue()" @mousedown="this.$refs.datePickerRef.openMenu()"
    readonly></v-text-field>
  <Datepicker month-picker v-model="yearMonth" ref="datePickerRef" @update:model-value="save()" locale="ja"
    select-text="選択" cancel-text="キャンセル" :enable-time-picker="false" :preview-format="format" position="left"
    :auto-position="false">
    <template #trigger>
    </template>
  </Datepicker>
</template>

<script>
import utils from '@/utils'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { Datepicker },
  props: {
    modelValue: String,
    label: String,
  },
  data: () => ({
    yearMonth: "",
    datePickerRef: "",
  }),

  computed: {
    yearMonthStr() {
      if (!this.yearMonth) return "";
      return `${this.yearMonth.year}-${String(this.yearMonth.month + 1).padStart(2, "0")}`;
    },
  },
  methods: {
    clear() {
      this.$refs.datePickerRef.clearValue()
    },
    save() {
      this.$emit("update:modelValue", this.yearMonthStr);
    },
    format: (date) => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      return `${year}-${month}`;
    },
    setDate(d) {
      this.yearMonth = d;
      this.save();
    },
  },
};
</script>
